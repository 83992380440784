jQuery(document).ready(function($) {
    
	$('.team-member').click(function() {
		var id = $(this).data('id');

		$('.team-overlay').addClass('active');
		$('.team-overlay__item.active').removeClass('active');
		$('#team-overlay__item--' + id).addClass('active');
	});

	$('.team-overlay').click(function(e) {
		// Check overlay element is clicked only
		if(e.target !== e.currentTarget) return;
		
		$('.team-overlay__item.active').removeClass('active');
		$(this).removeClass('active');
	});
 
});