jQuery(document).ready(function($) {

    function headerSticky() {
        var scroll = $(window).scrollTop();

        if (scroll > 1) {
            $('#header').addClass('sticky');
        } else {
            $('#header').removeClass('sticky');
        }
    }
    headerSticky();

    $(window).scroll(function() {    
        headerSticky();
    });
    
});