jQuery(document).ready(function($) {
    
	$('.statements__icons-nav li').click(function() {
		var id = $(this).data('id');

		$('.statements__icons-nav li.active').removeClass('active');
		$(this).addClass('active');

		$('.statements__icons-copy > div.active').removeClass('active');
		$('#statements__icons-copy--' + id).addClass('active');
	});
 
});