jQuery(document).ready(function($) {
    
	$('.portfolio__tags li').click(function() {
		var id = $(this).data('id');

		$('.portfolio__tags li.active').removeClass('active');
		$(this).addClass('active');

		$('.portfolio-list__item.active').removeClass('active');
		$('.portfolio-list__item.portfolio-type--' + id).addClass('active');
	});
 
});